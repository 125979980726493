import React, { useEffect, useMemo } from "react";

import getConfig from "next/config";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";

import { CssBaseline, ThemeProvider } from "@mui/material";

import { CacheProvider } from "@emotion/react";
import "lazysizes";
import {
  AmplitudeProvider,
  GraphQLClientContext,
  RegionProvider,
} from "market-webapp-commons";
import { DefaultSeo } from "next-seo";

import { SettingsProvider } from "../contexts/SettingsContext";
import createEmotionCache from "../createEmotionCache";
import GraphQLClient from "../graphql-client";
import useSettings from "../hooks/useSettings";
import { SeoDefaultConfig } from "../seo";
import createCustomTheme from "../theme";
import "./_app.css";

// https://github.com/mui-org/material-ui/tree/285e72875b9e7c9d6fc7303ccd54f0db16a4160d/examples/nextjs/
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

const NEXT_PUBLIC_GTAG_ID =
  publicRuntimeConfig?.NEXT_PUBLIC_GTAG_ID ??
  serverRuntimeConfig?.NEXT_PUBLIC_GTAG_ID ??
  process.env.NEXT_PUBLIC_GTAG_ID;

const App = ({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}) => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      window.gtag("config", NEXT_PUBLIC_GTAG_ID, {
        page_path: url,
      });
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  const { settings } = useSettings();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });
  const getLayout = Component.getLayout ?? ((page) => page);

  const graphqlClientContextValue = useMemo(
    () => ({ client: GraphQLClient }),
    []
  );

  return (
    <AmplitudeProvider>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
        </Head>
        <Script
          async
          // google tag manager
          // https://nextjs.org/docs/messages/next-script-for-ga
          src={`https://www.googletagmanager.com/gtag/js?id=${NEXT_PUBLIC_GTAG_ID}`}
          strategy="afterInteractive"
        />
        <Script id="google-analytics" strategy="afterInteractive">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "${NEXT_PUBLIC_GTAG_ID}");
        `}
        </Script>
        <DefaultSeo {...SeoDefaultConfig} />
        <GraphQLClientContext.Provider value={graphqlClientContextValue}>
          <SettingsProvider>
            <RegionProvider>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                {getLayout(<Component {...pageProps} />)}
              </ThemeProvider>
            </RegionProvider>
          </SettingsProvider>
        </GraphQLClientContext.Provider>
      </CacheProvider>
    </AmplitudeProvider>
  );
};

export default App;
